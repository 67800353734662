import { Fragment } from 'react';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import { getDynamicTableRow } from 'api/sections';
import { sectionDefinitionComponentMapping } from 'sections';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditableWrapper from 'components/EditableWrapper';

export const getSectionComponent = (section) => {
  return sectionDefinitionComponentMapping[section?.section_definition?.section_definition_id];
};

export const fetchSectionProps = (section) => {
  const { section_definition, dyn_t } = section || {};
  const collectionDefinition = section_definition?.collection_definition;
  const collectionDefinitionAttributes = collectionDefinition?.attributes;
  const collectionDefinitionId = collectionDefinition?.collection_definition_id;

  return ((collectionDefinitionId && dyn_t) ? (
    getDynamicTableRow(collectionDefinitionId, dyn_t, {})
  ) : Promise.resolve({ data: null }))
    .then(({ data }) => {
      const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
      const jsonValue = JSON.parse(jsonStrValue || '{}');
      const sectionProps = (collectionDefinitionAttributes || []).reduce((map, attr) => {
        const { attribute_id, name, data_type, default_value } = attr;
        const updatedMap = { ...map };
        let attrValue = (attribute_id in jsonValue) ? jsonValue[attribute_id] : default_value;
        switch (data_type) {
          case 1:
            attrValue = toLower(attrValue) === 'true';
            break;
          case 2:
            attrValue = Number(attrValue);
            break;
          case 4:
            attrValue = String(attrValue);
            break;
          case 8:
            attrValue = JSON.parse(attrValue || '{}');
            break;
          default:
        }
        updatedMap[name] = attrValue;
        return updatedMap;
      }, {});
      return sectionProps;
    });
};

export const renderSectionComponent = (section, sectionProps, editable, onPressEdit) => {
  const { section_id, is_viewport } = section || {};
  const SectionComponent = getSectionComponent(section);
  const { container_props, ...otherSectionProps } = sectionProps || {};
  const Viewport = is_viewport ? Container : Fragment;
  return SectionComponent ? (
    <EditableWrapper
      editable={editable}
      onPressEdit={() => onPressEdit(section_id)}
    >
      <Viewport>
        <MKBox {...container_props}>
          <SectionComponent
            editable={editable}
            onPressEdit={onPressEdit}
            {...otherSectionProps}
          />
        </MKBox>
      </Viewport>
    </EditableWrapper>
  ) : null;
};
