import SimpleHeaderSection from 'sections/SimpleHeader';
import MenuHeaderSection from 'sections/MenuHeaderSection';
import SimpleFooterSection from 'sections/SimpleFooter';
import ContactFooterSection from 'sections/ContactFooter';
import LoginSection from 'sections/Login';
import TextSection from 'sections/Text';
import ImageSection from 'sections/Image';
import ButtonSection from 'sections/Button';
import FlexBoxSection from 'sections/FlexBox';
import GridsSection from 'sections/Grids';

export const sectionDefinitionComponentMapping = {
  '25310d81-d558-40a8-bac5-18be605cae8f': SimpleHeaderSection,
  '092b2173-d14f-4f94-998c-0470cc0d6577': MenuHeaderSection,
  'a32667b8-22a4-4efe-9986-16877aa9076a': SimpleFooterSection,
  '5234c831-83fc-4e57-879d-b45ea6e18120': ContactFooterSection,
  '7b7002a2-bcc1-4ba5-a12f-8e6b9af7cc78': LoginSection,
  '656bb778-f965-457d-8f79-9e5aaaeda369': TextSection,
  '0187cbe3-eaba-44a4-bd2d-0393c20f99b3': ImageSection,
  'dc5ed9fd-fcff-4320-81bc-f1b4ee55e637': ButtonSection,
  '2f9195b1-6c86-448a-8bf2-74196c8e8788': FlexBoxSection,
  'a56b67a1-f366-4891-b89e-8b275e33e7a4': GridsSection,
};
