import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import startCase from 'lodash/startCase';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';

const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const EditAttributesForm = ({ attributes, config, onSave, onCancel, ...props }) => {
  const initialValues = useMemo(() => {
    return (attributes || []).reduce((map, attr) => {
      const { attribute_id, default_value } = attr;
      const updatedMap = { ...map };
      updatedMap[attribute_id] = (attribute_id in config) ? config[attribute_id] : default_value;
      return updatedMap;
    }, {});
  }, [attributes, config]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            {(attributes || []).map((attribute) => {
              const { attribute_id, name, data_type } = attribute;
              switch (data_type) {
                case 1: { // BOOLEAN
                  return (
                    <MKBox key={attribute_id} mb={2}>
                      <Select
                        name={attribute_id}
                        label={startCase(name)}
                        value={values[attribute_id] || ''}
                        onChange={(v) => setFieldValue(attribute_id, v)}
                        options={booleanOptions}
                      />
                    </MKBox>
                  );
                }
                case 2: // NUMBER
                case 4: // TEXT
                case 8: { // JSON
                  return (
                    <MKBox key={attribute_id} mb={2}>
                      <MKInput
                        name={attribute_id}
                        multiline={data_type === 8}
                        label={startCase(name)}
                        type={data_type === 2 ? 'number' : 'text'}
                        value={values[attribute_id] || ''}
                        onChange={handleChange(attribute_id)}
                        onBlur={handleBlur(attribute_id)}
                        fullWidth
                      />
                    </MKBox>
                  );
                }
                default: {
                  return null;
                }
              }
            })}
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditAttributesForm.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute_id: PropTypes.string,
      name: PropTypes.any,
      data_type: PropTypes.number,
      default_value: PropTypes.any,
    }),
  ),
  config: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditAttributesForm.defaultProps = {
  attributes: [],
  config: {},
  onSave: () => {},
  onCancel: () => {},
};

export default EditAttributesForm;
