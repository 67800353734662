import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MKBox from 'components/MaterialKit/MKBox';

export const DrawerMenuItem = ({ menuItem, ...props }) => {
  const { type, label, path, menu_items } = menuItem;
  return (
    <ListItem disablePadding {...props}>
      {type === 1 ? (
        <MKBox width="100%">
          <ListItemButton disabled>
            <ListItemText primary={label} />
          </ListItemButton>
          <List sx={{ pl: 2 }}>
            {(menu_items || []).map((subItem, i) => (
              <DrawerMenuItem
                key={`${subItem.menu_item_id}-${i}`}
                menuItem={subItem}
              />
            ))}
          </List>
        </MKBox>
      ) : (
        <ListItemButton LinkComponent={Link} to={path}>
          <ListItemText primary={label} />
        </ListItemButton>
      )}
    </ListItem>
  );
};

DrawerMenuItem.propTypes = {
  menuItem: PropTypes.shape({
    type: PropTypes.number,
    label: PropTypes.string,
    path: PropTypes.string,
    menu_items: PropTypes.arrayOf(PropTypes.object),
  }),
};

DrawerMenuItem.defaultProps = {
  menuItem: {},
};
