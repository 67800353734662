import AppSummaryPage from 'pages/AppSummary';
import EditPagePage from 'pages/EditPage';
import EditMenuPage from 'pages/EditMenu';
import EditMenuItemDependenciesPage from 'pages/EditMenuItemDependencies';
import EditSectionAttributesPage from 'pages/EditSectionAttributes';
import EditSectionPage from 'pages/EditSection';
import UniversalPage from 'pages/Universal';

const routes = [
  {
    name: 'AppSummary',
    route: '/app/:appId',
    component: <AppSummaryPage />,
  },
  {
    name: 'EditPage',
    route: '/app/:appId/page/:pageId?',
    component: <EditPagePage />,
  },
  {
    name: 'EditMenu',
    route: '/app/:appId/menu/:menuId',
    component: <EditMenuPage />,
  },
  {
    name: 'EditMenuItemDependency',
    route: '/app/:appId/menu/:menuId/menu-item-dependency/:menuItemDependencyId?/:action?',
    component: <EditMenuItemDependenciesPage />,
  },
  {
    name: 'EditSection',
    route: '/app/:appId/page/:pageId/section/:sectionId?',
    component: <EditSectionPage />,
  },
  {
    name: 'EditSection',
    route: '/section-attributes/:sectionId',
    component: <EditSectionAttributesPage />,
  },
  {
    name: 'Universal',
    route: '*',
    component: <UniversalPage />,
  },
];

export default routes;
