import get from 'lodash/get';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AppEditBarSection from 'sections/AppEditBar';
import LayoutEditBarSection from 'sections/LayoutEditBar';
import { getPages } from 'api/pages';
import { getMenu, getMenuItemDependencies } from 'api/menus';
import { MenuProvider } from 'contexts/menu';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import MKBox from 'components/MaterialKit/MKBox';
import DrawerMenu from 'components/DrawerMenu';
import EditableWrapper from 'components/EditableWrapper';

const UniversalPage = () => {
  const [page, setPage] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sectionsProps, setSectionsProps] = useState({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const layout = useMemo(() => get(page, 'layout') || {}, [page]);
  const sections = useMemo(() => get(page, 'sections') || [], [page]);
  const isAuthorMode = useMemo(() => searchParams.get('mode') === 'author', [searchParams]);

  const fetchPageFromApi = useCallback(() => {
    const pageParams = {
      path: location.pathname,
      $expand: 'app,layout/header/section_definition/collection_definition/attributes,layout/footer/section_definition/collection_definition/attributes,sections/section_definition/collection_definition/attributes',
    };
    return getPages(pageParams)
      .then(({ data }) => {
        if (data.length > 0) {
          setPage(data[0]);
        } else {
          setPage(null);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [location.pathname]);

  const fetchSectionDataFromApi = useCallback((section) => {
    const { section_id } = section;
    return fetchSectionProps(section)
      .then((sectionProps) => {
        setSectionsProps((oriSectionsProps) => {
          const updatedSectionsProps = { ...oriSectionsProps };
          updatedSectionsProps[section_id] = sectionProps;
          return updatedSectionsProps;
        });
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const fetchMenuItemsFromApi = useCallback((menuNodeId) => {
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $expand: 'menu_item',
      $orderBy: 'sequence',
    };
    return getMenuItemDependencies(menuItemDependencyParams)
      .then(({ data: menuItemDependencies }) => {
        const menuItems = (menuItemDependencies || []).map(({ menu_item }) => menu_item);
        return menuItems;
      });
  }, []);

  const fetchRecursiveMenuItemsFromApi = useCallback(async (menuNodeId) => {
    const menuItems = await fetchMenuItemsFromApi(menuNodeId);
    const updatedMenuItems = await Promise.all(menuItems.map(async (menuItem) => {
      const { menu_item_id, type } = menuItem;
      if (type === 1) {
        const menuSubItems = await fetchRecursiveMenuItemsFromApi(menu_item_id);
        return {
          ...menuItem,
          menu_items: menuSubItems,
        };
      }
      return menuItem;
    }));
    return updatedMenuItems;
  }, [fetchMenuItemsFromApi]);

  const fetchMenuFromApi = useCallback(() => {
    if (layout?.menu) {
      const menuParams = {
        $expand: 'menu_type,menu_node',
      };
      return getMenu(layout.menu, menuParams)
        .then(async ({ data }) => {
          const { menu_node } = data;
          if (menu_node?.type === 1) {
            const aggregatedMenuItems = await fetchRecursiveMenuItemsFromApi(menu_node?.menu_item_id);
            setMenu({
              ...data,
              menu_node: {
                ...menu_node,
                menu_items: aggregatedMenuItems,
              },
            });
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [layout.menu, fetchRecursiveMenuItemsFromApi]);

  const onPressEditSection = useCallback((sectionId) => {
    navigate(`/section-attributes/${sectionId}?from=${location.pathname}`);
  }, [location.pathname, navigate]);

  const onPressEditApp = useCallback(() => {
    navigate(`/app/${page?.app?.app_id}`);
  }, [page?.app?.app_id, navigate]);

  const onPressEditPage = useCallback(() => {
    navigate(`/app/${page?.app?.app_id}/page/${page?.page_id}`);
  }, [page, navigate]);

  const onPressEditMenu = useCallback((menuId) => {
    navigate(`/app/${page?.app?.app_id}/menu/${menuId}`);
  }, [page?.app?.app_id, navigate]);

  useEffect(() => {
    const totalSections = [
      ...(layout?.header ? [layout.header] : []),
      ...(layout?.footer ? [layout.footer] : []),
      ...sections,
    ];
    (totalSections || []).forEach((section) => {
      fetchSectionDataFromApi(section);
    });
  }, [layout, sections, fetchSectionDataFromApi]);

  useEffect(() => {
    fetchPageFromApi();
  }, [fetchPageFromApi]);

  useEffect(() => {
    fetchMenuFromApi();
  }, [fetchMenuFromApi]);

  useEffect(() => {
    if (!isAuthorMode && (typeof page?.refresh_rate === 'number') && page?.refresh_rate > 0) {
      const numberOfSec = (page?.refresh_rate || 0) * 1000;
      const intervalTimerId = setInterval(() => {
        fetchPageFromApi();
      }, numberOfSec);
      return () => {
        clearInterval(intervalTimerId);
      };
    }
  }, [fetchPageFromApi, isAuthorMode, page?.refresh_rate]);

  return page ? (
    <MKBox display="flex" flexDirection="column" sx={{ height: '100%' }}>
      {isAuthorMode && (
        <MKBox bgColor="light" shadow="md" sx={{ zIndex: 1 }}>
          <EditableWrapper editable onPressEdit={onPressEditApp}>
            <AppEditBarSection app={page?.app} />
          </EditableWrapper>
          <EditableWrapper editable onPressEdit={onPressEditPage}>
            <LayoutEditBarSection layout={page?.layout} />
          </EditableWrapper>
        </MKBox>
      )}
      <MenuProvider value={{ menu, setMenu }}>
        <MKBox
          flex={1}
          display="flex"
          sx={{
            backgroundImage: `url(${page?.background_image_url})`,
            backgroundColor: page?.background_color,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        >
          {['sidebar', 'drawer'].includes(menu?.menu_type?.name) && (
            <EditableWrapper
              editable={isAuthorMode}
              onPressEdit={() => onPressEditMenu(menu?.menu_id)}
              sx={{
                '.MuiDrawer-paper': {
                  position: 'relative',
                },
              }}
            >
              <DrawerMenu menu={menu} />
            </EditableWrapper>
          )}
          <MKBox display="flex" flexDirection="column" flex={1}>
            {renderSectionComponent(layout?.header, sectionsProps[layout?.header?.section_id], isAuthorMode, onPressEditSection)}
            <MKBox flex={1}>
              {sections.sort((s1, s2) => s1.sequence - s2.sequence).map((section, idx) => {
                return (
                  <Fragment key={`${section.section_id}-${idx}`}>
                    {renderSectionComponent(section, sectionsProps[section.section_id], isAuthorMode, onPressEditSection)}
                  </Fragment>
                );
              })}
            </MKBox>
            {renderSectionComponent(layout?.footer, sectionsProps[layout?.footer?.section_id], isAuthorMode, onPressEditSection)}
          </MKBox>
        </MKBox>
      </MenuProvider>
    </MKBox>
  ) : null;
};

export default UniversalPage;
