import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import Select from 'components/Select';

const formValidation = (values) => {
  const { section_definition } = values;
  const errors = {};
  if (!section_definition) {
    errors.section_definition = 'Required';
  }
  return errors;
};

const EditSectionForm = ({ section, sectionDefinitions, onSubmit, onCancel, ...props }) => {
  const initialValues = useMemo(() => ({
    section_definition: section?.section_definition || null,
    sequence: section?.sequence || 0,
    is_viewport: section?.is_viewport || false,
  }), [section]);

  const sectionDefinitionOptions = useMemo(() => (sectionDefinitions || []).map((sectionDefinition) => ({
    label: sectionDefinition.collection_definition?.name,
    value: sectionDefinition.section_definition_id,
  })), [sectionDefinitions]);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
      validate={formValidation}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={12}>
                <Select
                  label="Section Definition"
                  value={values.section_definition}
                  onChange={(v) => setFieldValue('section_definition', v)}
                  options={sectionDefinitionOptions}
                  invalid={touched.section_definition && !!errors.section_definition}
                  readOnly={!!section?.section_definition}
                />
              </Grid>
              <Grid item xs={12}>
                <MKInput
                  name="sequence"
                  label="Sequence"
                  type="number"
                  value={values.sequence || ''}
                  onChange={handleChange('sequence')}
                  onBlur={handleBlur('sequence')}
                  error={touched.sequence && !!errors.sequence}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Is Viewport"
                  value={values.is_viewport}
                  onChange={(v) => setFieldValue('is_viewport', v)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                  invalid={touched.is_viewport && !!errors.is_viewport}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : 'Save'}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditSectionForm.propTypes = {
  section: PropTypes.shape({
    section_definition: PropTypes.string,
    sequence: PropTypes.number,
    is_viewport: PropTypes.bool,
  }),
  sectionDefinitions: PropTypes.arrayOf(
    PropTypes.shape({
      collection_definition: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  ),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

EditSectionForm.defaultProps = {
  section: {},
  onSubmit: () => {},
  onCancel: () => {},
};

export default EditSectionForm;
