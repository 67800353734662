import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { getMenu, updateMenu, updateMenuItem } from 'api/menus';
import EditMenuForm from './EditMenuForm';
import MenuItemList from './MenuItemList';

const EditMenuPage = () => {
  const [menu, setMenu] = useState(null);
  const { menuId } = useParams();
  const { appId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchMenuFromApi = useCallback(() => {
    const menuParams = {
      $expand: 'menu_node',
    };
    return getMenu(menuId, menuParams)
      .then(({ data }) => {
        setMenu(data);
      })
      .catch((err) => {
        console.log('err.message', err.message);
      });
  }, [menuId]);

  const onClickCancel = useCallback(() => {
    if (location.key !== 'default') {
      return navigate(-1);
    }
    return navigate(`/app/${appId}`);
  }, [appId, location, navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const menuBody = {
      display_name: values.display_name,
    };
    const menuItemBody = {
      label: values.node_label,
    };
    return Promise.all([
      updateMenu(menu?.menu_id, menuBody),
      updateMenuItem(menu?.menu_node?.menu_item_id, menuItemBody),
    ])
      .then(() => {
        return fetchMenuFromApi();
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [menu, fetchMenuFromApi]);

  useEffect(() => {
    fetchMenuFromApi();
  }, [fetchMenuFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditMenuForm
          menu={menu}
          onSave={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        <MenuItemList menuNodeId={menu?.menu_node?.menu_item_id} />
      </Container>
    </MKBox>
  );
};

export default EditMenuPage;
