import api from 'api';

export const getMenus = (params) => {
  return api.get('/menus', params);
};

export const getMenu = (id, params) => {
  return api.get(`/menus/${id}`, params);
};

export const updateMenu = (id, params) => {
  return api.put(`/menus/${id}`, params);
};

export const getMenuItems = (params) => {
  return api.get('/menu_items', params);
};

export const getMenuItem = (id, params) => {
  return api.get(`/menu_items/${id}`, params);
};

export const updateMenuItem = (id, body) => {
  return api.put(`/menu_items/${id}`, body);
};

export const createMenuItem = (body) => {
  return api.post('/menu_items', body);
};

export const getMenuItemDependencies = (params) => {
  return api.get('/menu_item_dependencies', params);
};

export const getMenuItemDependency = (id, params) => {
  return api.get(`/menu_item_dependencies/${id}`, params);
};

export const updateMenuItemDependency = (id, body) => {
  return api.put(`/menu_item_dependencies/${id}`, body);
};

export const createMenuItemDependency = (body) => {
  return api.post('/menu_item_dependencies', body);
};
