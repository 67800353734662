import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTable from 'components/MaterialKit/MKTable';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getMenus } from 'api/menus';

const menuTableColumns = [
  { name: 'display_name', align: 'left' },
  { name: 'menu_node', label: 'Starting Menu Node', align: 'center' },
  { name: 'actions', align: 'center' },
];

const MenuSection = () => {
  const [menus, setMenus] = useState([]);
  const { appId } = useParams();

  const fetchMenusFromApi = useCallback(() => {
    const menuParams = {
      $expand: 'menu_node',
    };
    return getMenus(menuParams)
      .then(({ data }) => {
        setMenus(data);
      })
      .catch((err) => {
        console.log('err.message', err.message);
      });
  }, []);

  useEffect(() => {
    fetchMenusFromApi();
  }, [fetchMenusFromApi]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Menus</MKTypography>
      </MKBox>
      <MKTable
        columns={menuTableColumns}
        rows={menus.map((menu) => {
          const { menu_id, display_name, menu_node } = menu;
          return {
            display_name,
            menu_node: menu_node?.label,
            actions: (
              <MKBox display="flex">
                <Button
                  to={`/app/${appId}/menu/${menu_id}`}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  fontSize={12}
                >
                  Edit
                </Button>
              </MKBox>
            ),
          };
        })}
      />
    </MKBox>
  );
};

export default MenuSection;
