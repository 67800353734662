import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPage, updatePage, createPage, getLayouts } from 'api/pages';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditPageForm from 'pages/EditPage/EditPageForm';
import SectionList from 'pages/EditPage/SectionList';

const EditPagePage = () => {
  const [page, setPage] = useState(null);
  const [layouts, setLayouts] = useState([]);
  const { appId, pageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const fetchLayoutsFromApi = useCallback(() => {
    const layoutParams = {};
    return getLayouts(layoutParams)
      .then(({ data }) => {
        setLayouts(data);
      });
  }, []);

  const fetchPageFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const pageParams = {};
    return getPage(pageId, pageParams)
      .then(({ data }) => {
        setPage(data);
      });
  }, [pageId]);

  const onClickCancel = useCallback(() => {
    if (location.key !== 'default') {
      return navigate(-1);
    }
    return navigate(`/app/${appId}`);
  }, [appId, location, navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const pageBody = {
      app: appId,
      layout: values.layout,
      path: (values.path || '').startsWith('/') ? values.path : `/${values.path}`,
      background_color: values.background_color,
      background_image_url: values.background_image_url,
      refresh_rate: values.refresh_rate,
    };
    return (pageId ? updatePage(pageId, pageBody) : createPage(pageBody))
      .then(({ data }) => {
        navigate(`/app/${appId}`);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [pageId, appId, navigate]);

  useEffect(() => {
    Promise.all([
      fetchLayoutsFromApi(),
      fetchPageFromApi(),
    ]).catch((err) => {
      console.log('err', err);
    });
  }, [fetchLayoutsFromApi, fetchPageFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditPageForm
          page={page}
          layouts={layouts}
          onSubmit={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        <SectionList />
      </Container>
    </MKBox>
  );
};

export default EditPagePage;
