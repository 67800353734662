import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MKBox from 'components/MaterialKit/MKBox';
import MKTable from 'components/MaterialKit/MKTable';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getSections, deleteSection, deleteDynamicTableRow } from 'api/sections';

const sectionTableColumns = [
  { name: 'sequence', align: 'left' },
  { name: 'collection_definition', align: 'center' },
  { name: 'is_viewport', align: 'center' },
  { name: 'actions', align: 'center' },
];

const SectionList = () => {
  const [sections, setSections] = useState([]);
  const { appId, pageId } = useParams();

  const fetchSectionsFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const sectionParams = {
      page: pageId,
      $orderBy: 'sequence',
      $expand: 'section_definition/collection_definition',
    };
    return getSections(sectionParams)
      .then(({ data }) => {
        setSections(data);
      });
  }, [pageId]);

  const deleteSectionFromApi = useCallback((section) => {
    const { section_id, section_definition, dyn_t } = section;
    const collectionDefinitionId = section_definition?.collection_definition?.collection_definition_id;
    return deleteSection(section_id)
      .then(() => deleteDynamicTableRow(collectionDefinitionId, dyn_t))
      .then(() => fetchSectionsFromApi())
      .catch((err) => {
        console.log('err', err);
      });
  }, [fetchSectionsFromApi]);

  const onClickDeleteSection = useCallback((section) => {
    if (window.confirm('Do you confirm to delete this section?')) {
      return deleteSectionFromApi(section);
    }
    return Promise.resolve();
  }, [deleteSectionFromApi]);

  useEffect(() => {
    fetchSectionsFromApi();
  }, [fetchSectionsFromApi]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Sections</MKTypography>
        <Button
          to={`/app/${appId}/page/${pageId}/section`}
          color="success"
          variant="gradient"
          iconOnly
          circular
        >
          <AddIcon />
        </Button>
      </MKBox>
      <MKTable
        columns={sectionTableColumns}
        rows={sections.map((section) => {
          const { section_id, sequence, is_viewport, section_definition } = section;
          const collectionDefinition = section_definition?.collection_definition;
          return {
            sequence,
            collection_definition: collectionDefinition?.name,
            is_viewport: is_viewport ? 'Yes' : 'No',
            actions: (
              <MKBox display="flex">
                <MKBox mr={1}>
                  <Button
                    to={`/app/${appId}/page/${pageId}/section/${section_id}`}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    fontSize={12}
                  >
                    Edit
                  </Button>
                </MKBox>
                <Button
                  onClick={() => onClickDeleteSection(section)}
                  variant="gradient"
                  color="error"
                  size="small"
                  fontSize={12}
                >
                  Delete
                </Button>
              </MKBox>
            ),
          };
        })}
      />
    </MKBox>
  );
};

export default SectionList;
