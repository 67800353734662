import api from 'api';

export const getApps = (params) => {
  return api.get('/apps', params);
};

export const getApp = (id, params) => {
  return api.get(`/apps/${id}`, params);
};

export const updateApp = (id, body) => {
  return api.put(`/apps/${id}`, body);
};
