import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MKBox from 'components/MaterialKit/MKBox';
import MKTable from 'components/MaterialKit/MKTable';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getPages } from 'api/pages';

const pageTableColumns = [
  { name: 'page_id', label: 'Page ID', align: 'left' },
  { name: 'path', align: 'center' },
  { name: 'layout', align: 'center' },
  { name: 'action', align: 'center' },
];

const PageSection = () => {
  const [pages, setPages] = useState([]);
  const { appId } = useParams();

  const fetchPagesFromApi = useCallback(() => {
    const pageParams = {
      app: appId,
      $expand: 'layout',
    };
    return getPages(pageParams)
      .then(({ data }) => {
        setPages(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [appId]);

  useEffect(() => {
    fetchPagesFromApi();
  }, [fetchPagesFromApi]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Pages</MKTypography>
        <Button
          to={`/app/${appId}/page`}
          color="success"
          variant="gradient"
          iconOnly
          circular
        >
          <AddIcon />
        </Button>
      </MKBox>
      <MKTable
        columns={pageTableColumns}
        rows={pages.map((page) => {
          const { page_id, path, layout } = page;
          return {
            page_id,
            path,
            layout: layout?.name,
            action: (
              <MKBox display="flex">
                <MKBox mr={1}>
                  <Button
                    to={`/app/${appId}/page/${page_id}`}
                    variant="outlined"
                    color="secondary"
                    size="small"
                    fontSize={12}
                  >
                    Edit
                  </Button>
                </MKBox>
                <MKBox mr={1}>
                  <Button
                    to={`${path}?mode=author`}
                    variant="gradient"
                    color="secondary"
                    size="small"
                    fontSize={12}
                  >
                    Author
                  </Button>
                </MKBox>
                <MKBox>
                  <Button
                    to={path}
                    variant="gradient"
                    color="info"
                    size="small"
                    fontSize={12}
                  >
                    User
                  </Button>
                </MKBox>
              </MKBox>
            ),
          };
        })}
      />
    </MKBox>
  );
};

export default PageSection;
