import { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MKBox from 'components/MaterialKit/MKBox';
import theme from 'assets/theme';
import routes from 'routes';

const App = () => {
  const { pathname } = useLocation();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.name} />;
      }

      return null;
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MKBox display="flex" flexDirection="column" height={1}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MKBox>
    </ThemeProvider>
  );
};

export default App;
