import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';

const EditMenuForm = ({ menu, onSave, onCancel, ...props }) => {
  const initialValues = useMemo(() => ({
    display_name: menu?.display_name || '',
    node_label: menu?.menu_node?.label || '',
  }), [menu]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="display_name"
                  label="Display Name"
                  value={values.display_name || ''}
                  onChange={handleChange('display_name')}
                  onBlur={handleBlur('display_name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="node_label"
                  label="Node Label"
                  value={values.node_label || ''}
                  onChange={handleChange('node_label')}
                  onBlur={handleBlur('node_label')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      disabled={isSubmitting || !dirty}
                      fullWidth
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : 'Save'}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditMenuForm.propTypes = {
  menu: PropTypes.shape({
    display_name: PropTypes.string,
    menu_node: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.number,
      path: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditMenuForm.defaultProps = {
  menu: {},
  onSave: () => {},
  onCancel: () => {},
};

export default EditMenuForm;
