import React, { useContext } from 'react';

const MenuContext = React.createContext();
export const MenuProvider = MenuContext.Provider;
export const MenuConsumer = MenuContext.Consumer;

export const initialMenuState = {
  menu: null,
};

export const withMenu = (Component) => {
  return (props) => (
    <MenuConsumer>
      {({ menu, setMenu }) => (
        <Component
          menu={menu}
          setMenu={setMenu}
          {...props}
        />
      )}
    </MenuConsumer>
  );
};

export const useMenu = () => useContext(MenuContext);

export default MenuContext;
