import get from 'lodash/get';
import api, { getStoredAuth, setStoredAuth } from 'api';
import { getMe } from 'api/users';
import config from 'config';

let refreshTokenPromise = null;

export const refreshToken = (refToken) => {
  if (refreshTokenPromise) {
    return refreshTokenPromise;
  }
  const body = {
    grant_type: 'refresh_token',
    refresh_token: refToken,
  };
  refreshTokenPromise = api.postUrlFormEncoded('/auth', body, 'basic')
    .then((res) => {
      const { data: newAuth } = res;
      const storedAuth = getStoredAuth();
      newAuth.user = get(storedAuth, 'user');
      setStoredAuth(newAuth);
      return res;
    })
    .finally(() => {
      refreshTokenPromise = null;
    });
  return refreshTokenPromise;
};

export const login = (username, password) => {
  return api.postUrlFormEncoded('/auth', {
    username,
    password,
    grant_type: 'password',
    audience: config.oauthAudience,
  }, 'basic')
    .then(({ data }) => {
      const { access_token, refresh_token } = data;
      setStoredAuth(data);
      return getMe(null)
        .then(({ data: user }) => {
          return {
            token: access_token,
            refreshToken: refresh_token,
            user,
          };
        });
    });
};
