import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import Container from '@mui/material/Container';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getSection, getDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import MKBox from 'components/MaterialKit/MKBox';
import EditAttributesForm from 'components/EditAttributesForm';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';

const EditSectionAttributesPage = () => {
  const [currentSection, setCurrentSection] = useState(null);
  const [sectionConfig, setSectionConfig] = useState({});
  const { sectionId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { section_definition, dyn_t } = currentSection || {};
  const collectionDefinition = section_definition?.collection_definition;
  const collectionDefinitionId = collectionDefinition?.collection_definition_id;
  const collectionDefinitionAttributes = collectionDefinition?.attributes;

  const fetchSectionFromApi = useCallback(() => {
    const sectionParams = {
      $expand: 'section_definition/collection_definition/attributes',
    };
    return getSection(sectionId, sectionParams)
      .then(({ data }) => {
        setCurrentSection(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [sectionId]);

  const fetchSectionDataFromApi = useCallback(() => {
    return ((collectionDefinitionId && dyn_t) ? (
      getDynamicTableRow(collectionDefinitionId, dyn_t, {})
    ) : Promise.resolve({ data: null }))
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = JSON.parse(jsonStrValue || '{}');
        setSectionConfig(jsonValue);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [collectionDefinitionId, dyn_t]);

  const updateSectionDataToApi = useCallback((values) => {
    const updateBody = {
      json_short_data: JSON.stringify(values),
    };
    if (!collectionDefinitionId || !dyn_t) {
      return Promise.resolve();
    }
    return updateDynamicTableRow(collectionDefinitionId, dyn_t, updateBody)
      .then(({ data }) => {
        const jsonStrValue = get(data, 'json_short_data') || get(data, 'json_big_data');
        const jsonValue = JSON.parse(jsonStrValue || '{}');
        setSectionConfig(jsonValue);
        if (searchParams.get('from')) {
          navigate(`${searchParams.get('from')}?mode=author`);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [collectionDefinitionId, dyn_t, searchParams, navigate]);

  const onClickCancel = useCallback(() => {
    if (searchParams.get('from')) {
      navigate(`${searchParams.get('from')}?mode=author`);
    } else {
      navigate(-1);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    fetchSectionFromApi();
  }, [fetchSectionFromApi]);

  useEffect(() => {
    fetchSectionDataFromApi();
  }, [fetchSectionDataFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <MKBox display="flex" alignItems="center" mb="2rem">
          <MKTypography variant="h3">
            {collectionDefinition?.name}
          </MKTypography>
          <MKBox ml="1rem">
            <CopyToClipboard
              text={sectionId}
              onCopy={(text, result) => {
                console.log({ text, result });
              }}
            >
              <Button
                circular
                color="secondary"
                variant="gradient"
                size="small"
                fontSize={12}
              >
                <ContentCopyIcon sx={{ mr: '0.4rem' }} />
                Copy ID
              </Button>
            </CopyToClipboard>
          </MKBox>
        </MKBox>
        <EditAttributesForm
          attributes={collectionDefinitionAttributes}
          config={sectionConfig}
          onSave={updateSectionDataToApi}
          onCancel={onClickCancel}
        />
      </Container>
    </MKBox>
  );
};

export default EditSectionAttributesPage;
