import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MenuItemList from 'pages/EditMenu/MenuItemList';
import EditMenuItemDependencyForm from 'pages/EditMenuItemDependencies/EditMenuItemDependencyForm';
import { getMenu, updateMenuItem, getMenuItemDependency, createMenuItemDependency, updateMenuItemDependency } from 'api/menus';

const EditMenuItemDependenciesPage = () => {
  const { appId, menuId, menuItemDependencyId, action } = useParams();
  const [menuItemDependency, setMenuItemDependency] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchMenuItemDependencyFromApi = useCallback(() => {
    if (!menuItemDependencyId) {
      if (!menuId) {
        return Promise.resolve();
      }
      const menuParams = {};
      return getMenu(menuId, menuParams)
        .then(({ data }) => {
          const { menu_node } = data;
          setMenuItemDependency({ menu_node });
        })
        .catch((err) => {
          console.log('err.message', err.message);
        });
    }

    const menuItemDependencyParams = {
      $expand: 'menu_item',
    };
    return getMenuItemDependency(menuItemDependencyId, menuItemDependencyParams)
      .then(({ data }) => {
        if (action === 'add') {
          const { menu_item } = data;
          setMenuItemDependency({ menu_node: menu_item?.menu_item_id });
        } else {
          setMenuItemDependency(data);
        }
      })
      .catch((err) => {
        console.log('err.message', err.message);
      });
  }, [menuId, action, menuItemDependencyId]);

  const onClickCancel = useCallback(() => {
    if (location.key !== 'default') {
      return navigate(-1);
    }
    return navigate(`/app/${appId}/menu/${menuId}`);
  }, [appId, menuId, location, navigate]);

  const onClickSaveToApi = useCallback((values, { setFieldError }) => {
    const isCreating = !menuItemDependencyId || action === 'add';
    const menuItemBody = {
      label: values.label,
      type: values.type,
      path: values.type !== 1 ? values.path : null,
      disabled: values.disabled,
    };
    const menuItemDependencyBody = {
      sequence: values.sequence,
      ...(isCreating ? {
        menu_node: menuItemDependency?.menu_node,
        menu_item: menuItemBody,
      } : {}),
    };
    return (!isCreating ? Promise.all([
      updateMenuItem(menuItemDependency?.menu_item?.menu_item_id, menuItemBody),
      updateMenuItemDependency(menuItemDependencyId, menuItemDependencyBody),
    ]) : createMenuItemDependency(menuItemDependencyBody))
      .then(() => {
        if (!isCreating) {
          return fetchMenuItemDependencyFromApi();
        }
        navigate(`/app/${appId}/menu/${menuId}${menuItemDependencyId ? (
          `/menu-item-dependency/${menuItemDependencyId}`
        ) : ''}`);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [menuItemDependencyId, menuId, appId, menuItemDependency, action, fetchMenuItemDependencyFromApi, navigate]);

  useEffect(() => {
    fetchMenuItemDependencyFromApi();
  }, [fetchMenuItemDependencyFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditMenuItemDependencyForm
          menuItemDependency={menuItemDependency}
          onSave={onClickSaveToApi}
          onCancel={onClickCancel}
        />
        {menuItemDependency?.menu_item?.type === 1 && (
          <MenuItemList menuNodeId={menuItemDependency?.menu_item?.menu_item_id} />
        )}
      </Container>
    </MKBox>
  );
};

export default EditMenuItemDependenciesPage;
