import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditAppForm from 'pages/AppSummary/EditAppForm';
import { getApp, updateApp } from 'api/apps';
import PageSection from './PageSection';
import MenuSection from './MenuSection';

const AppSummaryPage = () => {
  const [app, setApp] = useState(null);
  const { appId } = useParams();

  const fetchAppFromApi = useCallback(() => {
    const appParams = {
      $expand: 'solution',
    };
    return getApp(appId, appParams)
      .then(({ data }) => {
        setApp(data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, [appId]);

  const updateAppToApi = useCallback((values, { setFieldError }) => {
    const updateBody = {
      display_name: values.display_name,
    };
    return updateApp(appId, updateBody)
      .then(({ data }) => {
        setApp(data);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [appId]);

  useEffect(() => {
    fetchAppFromApi();
  }, [fetchAppFromApi]);

  return (
    <MKBox flex={1} bgColor="light" py="2rem">
      <Container>
        <EditAppForm
          app={app}
          onSave={updateAppToApi}
        />
        <PageSection />
        <MenuSection />
      </Container>
    </MKBox>
  );
};

export default AppSummaryPage;
