import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { DrawerMenuItem } from './DrawerMenuItem';

const DEFAULT_MENU_WIDTH = 260;

const DrawerMenu = ({ menu, ...props }) => {
  const { display_name, menu_node } = menu;
  const { label, menu_items } = menu_node || {};
  return (
    <Drawer
      variant="permanent"
      anchor="left"
      sx={{
        'width': DEFAULT_MENU_WIDTH,
        'flexShrink': 0,
        '& .MuiDrawer-paper': {
          width: DEFAULT_MENU_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      {...props}
    >
      <MKBox display="flex" flexDirection="column" p={2}>
        <MKTypography variant="h5">{display_name}</MKTypography>
        <MKTypography variant="body2">{label}</MKTypography>
      </MKBox>
      <Divider sx={{ my: 1 }} />
      <List>
        {(menu_items || []).map((menuItem, i) => {
          return (
            <DrawerMenuItem
              key={`${menuItem.menu_item_id}-${i}`}
              menuItem={menuItem}
            />
          );
        })}
      </List>
    </Drawer>
  );
};

DrawerMenu.propTypes = {
  menu: PropTypes.shape({
    display_name: PropTypes.string,
    menu_node: PropTypes.shape({
      menu_items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.number,
          label: PropTypes.string,
          path: PropTypes.string,
          menu_items: PropTypes.array,
        }),
      ),
    }),
  }),
};

DrawerMenu.defaultProps = {
  menu: {},
};

export default DrawerMenu;
