import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import LoginForm from 'components/LoginForm';
import MKBox from 'components/MaterialKit/MKBox';
import { login } from 'api/auth';

const loginFormInitialValues = {
  username: '',
  password: '',
};

const LoginSection = ({ has_remember_me_option, enable_show_password, ...props }) => {
  const onLoginToApi = useCallback((values, { setFieldError }) => {
    return login(values.username, values.password)
      .then(({ data: user }) => {
        alert('Login Success');
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, []);

  return (
    <Container>
      <MKBox py="2rem">
        <LoginForm
          onLogin={onLoginToApi}
          initialValues={loginFormInitialValues}
          enableShowPassword={enable_show_password}
          enableRememberMeOption={has_remember_me_option}
          {...props}
        />
      </MKBox>
    </Container>
  );
};

LoginSection.propTypes = {
  enable_show_password: PropTypes.bool,
  has_remember_me_option: PropTypes.bool,
};

LoginSection.defaultProps = {
  enable_show_password: false,
  has_remember_me_option: false,
};

export default LoginSection;
