import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import MKBox from 'components/MaterialKit/MKBox';
import MKBadge from 'components/MaterialKit/MKBadge';
import MKTable from 'components/MaterialKit/MKTable';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getMenuItemDependencies } from 'api/menus';

const menuItemDependencyTableColumns = [
  { name: 'sequence', align: 'left' },
  { name: 'label', align: 'center' },
  { name: 'type', align: 'center' },
  { name: 'path', align: 'center' },
  { name: 'disabled', align: 'center' },
  { name: 'actions', align: 'center' },
];

const MenuItemList = ({ menuNodeId }) => {
  const [menuItemDependencies, setMenuItemDependencies] = useState([]);
  const { appId, menuId, menuItemDependencyId } = useParams();

  const fetchMenuItemDependenciesFromApi = useCallback(() => {
    if (!menuNodeId) {
      return Promise.resolve();
    }
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $orderBy: 'sequence',
      $expand: 'menu_item',
    };
    return getMenuItemDependencies(menuItemDependencyParams)
      .then(({ data }) => {
        setMenuItemDependencies(data);
      });
  }, [menuNodeId]);

  useEffect(() => {
    fetchMenuItemDependenciesFromApi();
  }, [fetchMenuItemDependenciesFromApi]);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Menu Items</MKTypography>
        <Button
          to={`/app/${appId}/menu/${menuId}/menu-item-dependency${menuItemDependencyId ? `/${menuItemDependencyId}/add` : ''}`}
          color="success"
          variant="gradient"
          iconOnly
          circular
        >
          <AddIcon />
        </Button>
      </MKBox>
      <MKTable
        columns={menuItemDependencyTableColumns}
        rows={menuItemDependencies.map((menuItemDependency) => {
          const { menu_item_dependency_id, sequence, menu_item } = menuItemDependency;
          const { type, label, path, disabled } = menu_item || {};
          return {
            sequence,
            label,
            type: (
              <MKBadge
                color={type === 1 ? 'secondary' : 'info'}
                container
                badgeContent={type === 1 ? 'Node' : 'Item'}
              />
            ),
            path,
            disabled: disabled ? 'Yes' : 'No',
            actions: (
              <MKBox display="flex">
                <Button
                  to={`/app/${appId}/menu/${menuId}/menu-item-dependency/${menu_item_dependency_id}`}
                  variant="outlined"
                  color="secondary"
                  fontSize={12}
                  size="small"
                >
                  Edit
                </Button>
              </MKBox>
            ),
          };
        })}
      />
    </MKBox>
  );
};

MenuItemList.propTypes = {
  menuNodeId: PropTypes.string,
};

MenuItemList.defaultProps = {
  menuNodeId: null,
};

export default MenuItemList;
