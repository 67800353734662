import api from 'api';

export const getSections = (params) => {
  return api.get('/sections', params);
};

export const getSection = (id, params) => {
  return api.get(`/sections/${id}`, params);
};

export const updateSection = (id, body) => {
  return api.put(`/sections/${id}`, body);
};

export const createSection = (body) => {
  return api.post('/sections', body);
};

export const deleteSection = (id) => {
  return api.delete(`/sections/${id}`);
};

export const getSectionDefinitions = (params) => {
  return api.get('/section_definitions', params);
};

export const getSectionDefinition = (id, params) => {
  return api.get(`/section_definitions/${id}`, params);
};

export const getDynamicTableRow = (collectionDefinitionId, id, params) => {
  return api.get(`/dyn_t/${collectionDefinitionId}/${id}`, params);
};

export const updateDynamicTableRow = (collectionDefinitionId, id, body) => {
  return api.put(`/dyn_t/${collectionDefinitionId}/${id}`, body);
};

export const createDynamicTableRow = (collectionDefinitionId, body) => {
  return api.post(`/dyn_t/${collectionDefinitionId}`, body);
};

export const deleteDynamicTableRow = (collectionDefinitionId, id) => {
  return api.delete(`/dyn_t/${collectionDefinitionId}/${id}`);
};
