import PropTypes from 'prop-types';
import { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';

const LoginForm = ({ onLogin, initialValues, enableRememberMeOption, enableShowPassword, ...props }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onLogin}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <MKBox mb={2}>
              <MKInput
                name="username"
                label="Username"
                value={values.username || ''}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                value={values.password || ''}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                fullWidth
                InputProps={{
                  endAdornment: enableShowPassword ? (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  ) : null,
                }}
              />
            </MKBox>
            {enableRememberMeOption && (
              <MKBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MKTypography>
              </MKBox>
            )}
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <MKBox mt={4} mb={1}>
              <Button type="submit" variant="gradient" color="info" size="large" fullWidth disabled={isSubmitting}>
                sign in
              </Button>
            </MKBox>
            <MKBox mt={3} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?
                {' '}
                <MKTypography
                  component={Link}
                  to="/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  initialValues: PropTypes.object,
  enableShowPassword: PropTypes.bool,
  enableRememberMeOption: PropTypes.bool,
};

LoginForm.defaultProps = {
  onLogin: () => {},
  initialValues: {},
  enableShowPassword: false,
  enableRememberMeOption: false,
};

export default LoginForm;
